var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataList
    ? _c("div", { attrs: { id: "knowledge-base-category-page" } }, [
        _c("h1", { staticClass: "text-primary mb-4" }, [
          _c("strong", [_vm._v(_vm._s(_vm.dataList[0].contentTypeName))])
        ]),
        _c(
          "div",
          { staticClass: "vx-row mt-8 match-height" },
          _vm._l(_vm.dataList, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "vx-col w-full md:w-1/3 sm:w-1/2 mb-base",
                on: {
                  click: function($event) {
                    return _vm.goToDetail(item.id, item.contentTypeId)
                  }
                }
              },
              [
                _c("vx-card", { staticClass: "text-center cursor-pointer" }, [
                  item.attachmentType == "MP4"
                    ? _c("iframe", {
                        attrs: {
                          width: "150",
                          src: item.attachementUrl,
                          frameborder: "0",
                          allow:
                            "accelerometer; encrypted-media; gyroscope; picture-in-picture",
                          allowfullscreen: ""
                        }
                      })
                    : _c("img", {
                        staticClass: "mx-auto mb-4",
                        attrs: {
                          src: item.attachementUrl,
                          width: "150",
                          alt: "graphic"
                        }
                      }),
                  _c("h4", { staticClass: "mb-2" }, [
                    _c("strong", [_vm._v(_vm._s(item.title.toUpperCase()))])
                  ]),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        item.body.replace("<p>", "").substring(0, 100) + " ..."
                      )
                    }
                  })
                ])
              ],
              1
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }