<template>
  <div id="knowledge-base-category-page" v-if="dataList">
    <h1 class="text-primary mb-4">
      <strong>{{ dataList[0].contentTypeName }}</strong>
    </h1>

    <div class="vx-row mt-8 match-height">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in dataList"
        :key="item.id"
        @click="goToDetail(item.id, item.contentTypeId)"
      >
        <vx-card class="text-center cursor-pointer">
          <iframe
            v-if="item.attachmentType == 'MP4'"
            width="150"
            :src="item.attachementUrl"
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            v-else
            :src="item.attachementUrl"
            width="150"
            alt="graphic"
            class="mx-auto mb-4"
          />
          <h4 class="mb-2">
            <strong>{{ item.title.toUpperCase() }}</strong>
          </h4>
          <div
            v-html="`${item.body.replace('<p>', '').substring(0, 100)} ...`"
          ></div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      contentTypeId: 0,
      dataList: []
    };
  },
  async created() {
    this.$vs.loading();

    this.contentTypeId = this.$route.params.contentTypeId;

    if (this.contentTypeId !== null && this.contentTypeId !== 0)
      await this.get();

    this.$vs.loading.close();
  },
  methods: {
    async get() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog/Search`,
        data: {
          searchKeys: {
            blogTypeId: 2,
            contentTypeId: this.contentTypeId
          },
          page: 1,
          rows: 100,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            this.dataList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    goToDetail(id, contentTypeId) {
      this.$router.push({
        name: "resources-news-detail",
        params: {
          id: id,
          contentTypeId: contentTypeId
        }
      });
    }
  },
  components: {},
  mounted() {
    this.$emit("changeRouteTitle", "Category");
  }
};
</script>
